@media (max-width: 767.98px) {

    body{
        background-size: 2400px 1600px;
    }
	
	.card {
        margin-bottom: 2rem;
    }
}
	