:root{
    --color-dark: #555555;
	--color-light: #999999;
	--color-very-light-gray:#EEEEEE;
	--color-input:#f8f4f1;
	--color-gold:#FFB580;
	--color-light-gold:#fdf5ee;
	--color-dark-gold:#AE8717;
	--color-semi-gold:#FFD5B5;
	--color-gold-text-dark-theme:#ad944e;
	--color-semi-gold-dark-theme:#d1bc81;
	--color-red:#DD3646;
	--color-green:#81CD34;
	--color-green-light:#A8E868;
	--theme-very-dark-bg:#302f2c;
	--theme-dark-bg:#393939;
	--theme-dark-color:#afafaf;
	--theme-dark-border:#464646;
	--theme-dark-input:#505050;
	--theme-dark-light-color:#ddd;
}
::-webkit-scrollbar {
    width: 5px!important;
    height: 5px!important;
}
::-webkit-scrollbar-track {
    background: rgba(0,0,0,0.2)!important;
}
::-webkit-scrollbar-thumb {
    background: var(--color-semi-gold)!important;
}
html {
	overflow-y: scroll !important; 
	min-height: 100%;
    display: flex;
	scroll-behavior: smooth;
}

html,body {
	font-size:10px;
	color:var(--color-dark);
	background-color:#EFF1F2;
	font-family:'Barlow', sans-serif !important;
	font-weight:400;
	height: auto !important;
	min-width: 100% !important;
}
body{
	background-image: url("../Images/bg.webp");
	background-position: top right;
	background-repeat: repeat-y;
	background-color: #F6F5EE;
	background-attachment: fixed;
}
body.ReactModal__Body--open{
	overflow:hidden;
}
:focus {
    outline: none;
}
a,
a:hover{
	color:#00aeef;
	cursor:pointer;
}
hr{
	border-top:4px solid rgba(0,0,0,.1);
}
button:focus,
a:focus {
    outline:0px none;
}

.whole-container{
	padding: 65px 15px 0px 265px;
	transition: 0.5s all;
}

#login{
	min-height: 100%;
}
#login .logo{
	display: flex;
}
#login .logo img{
	margin:100px auto 58px auto;
	display: block;
}
#login .logo .version{
	color:#603813;
	text-align:center;
	font-size:1.2rem;
}
#login .card{
	background-color:#fff;
	width:500px;
	margin:0 auto;
}
#login .inner-panel{
	border:1px solid #eeeeee;
	padding:35px 30px;
}

#login .card a{
    font-size:1.4rem;
}
#login .copy-tag{
	position: absolute;
    font-size: 1.2rem;
    bottom: -30px;
    left: 0px;
    color: #bbbbbb;
    width:100%;
    text-align:center;
}
#login input{
	padding-left:0px;
	font-size: 1.4rem;
	line-height: 1.4rem;
}
#login .input-group-prepend img{
	width:20px;
}
#header{
	width:100%;
	position:fixed;
	top:0px;
	left:0px;
	z-index:11;
    background-image: url("../Images/header_bg.webp");
	background-position: right center;
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
}
#header ul{
	list-style-type:none;
}

#header ul li a,
#header ul li button{
	padding:10px 10px;
	display:block;
	line-height: 25px;
	font-size: 1.2rem;
	color:#8A4C26;
	font-weight:bold;
}

#header ul li.theme button{
	padding:10px 5px 10px 10px;
}
#header ul li button{
	border:0px none;
	cursor:pointer;
	background-color: transparent;
}
#header ul li.logo{
	align-items: center;
    display: flex;
    font-size: 1.6rem;
    justify-content: center;
    padding: 0;
    text-align: center;
    text-transform: uppercase;
}
#header ul li.logo img{
	height:45px;
}
#header ul li.membership{
	padding:6px 10px;
	line-height:16px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size:1.4rem;
}
#header ul li.membership span{
	color:var(--color-gold);
}
#header ul li.mobile-membership{
	display:none;
}
#header ul li.renew-membership{
	display:flex;
	align-items: center;
}
#header ul li.renew-membership .btn{
	margin-right:1rem;
}
#header ul li.mobile-membership ul{
	position:absolute;
	top:45px;
	left:0px;
	background-color: #fff;
	width:100%;
	padding:10px;
	margin:0px;
	border-bottom: 1px solid #ddd;
    border-top: 1px solid #ddd;
	display:flex;
	align-items: center;
	z-index:3;
}
#header ul li.mobile-membership ul li{
	display:block;
}

#header ul li img{
	max-width:100%;
	height: 24px;
}
#header ul li.logo img{
	height:35px;
	margin-right:10px;
}
#header .glyphicons-door{
	font-size: 1.06rem;
}
#header .menu-icon{
	display:none;
}
#header .glyphicons-door{
	font-size: 1.06rem;
}
#header .menu-icon{
	display:none;
}

#header .sideBar{
	position:fixed;
	top:0px;
	left:0px;
	bottom:0px;
	width:250px;
	background-color:#fff;
	z-index:2;
	transition:0.5s transform;
	display:flex;
	flex-direction:column;
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
}
#header .sideBar ul li a,
#header .sideBar ul li button,
#header .sideBar ul li span{
	text-align:left;
	padding:10px 13px 10px 13px;
	transition:0.5s all;
	color:var(--color-dark);
	display:flex;
	align-items: center;
	position:relative;
	font-size:1.4rem;
	font-weight:400;
	width: 100%;
	line-height: 1.6rem;
}
#header .sideBar ul.all li a,
#header .sideBar ul.all li button,
#header .sideBar ul.all li span{
	padding:10px 13px 10px 20px;
}
#header .sideBar ul li a img{
	width:30px;
	height:30px;
	margin-right:12px;
	display:block;
}
#header .sideBar ul li a img.animated{
	display:none;
}
#header .sideBar ul li a:hover img.animated,
#header .sideBar ul li a img.static{
	display:block;
}
#header .sideBar ul li a:hover img.static{
	display:none;
}
#header .sideBar ul li a span{
	line-height: 12px;
}
#header .sideBar.open{
	transform: translateX(0px);
}
#header .sideBar ul{
	margin:0 15px;
	padding:0px;
}
#header .sideBar ul li{
	border-right:0px none;
	border-left:0px none;
	border-bottom:1px solid var(--color-very-light-gray);
	position:relative;
}

#header .sideBar .logo{
	width:100px;
	display: block;
	margin:30px auto;
}


#header .sideBar ul li ul{
	transition: all 0.5s;
	opacity:1;
	overflow:hidden;
	margin:0px;
	padding:0px;
}
#header .sideBar ul li ul.hidden{
	opacity:0;
	height:0px;
}
#header .sideBar ul li ul li{
	margin-left:28px;
}
#header .sideBar ul li ul li.last{
	border-bottom:0px none;
}
#header .sideBar ul li ul li a,
#header  .sideBar ul li ul li,
#header  .sideBar ul li ul li span{
	font-size:1.3rem;
	font-weight:normal;
	font-style: italic;
	position: relative;
	line-height: 2rem;
}
#header .sideBar ul li ul li{
	padding: 0px 0px 0px 5px; 
}
#header .sideBar ul li ul li a,
#header .sideBar ul li ul li span{
	padding: 10px 0px 10px 0px; 
}
#header .sideBar ul li ul li.activated,
#header .sideBar ul li ul li.activated a{
	color:#999999;
}
#header .sideBar ul li.deactivated,
#header .sideBar ul li.deactivated span{
	color:#ddd;
}
#header .sideBar ul li ul li.activated a.active{
	color:var(--color-gold);
}

#header .sideBar ul li a.active,
#header .sideBar ul li ul li.activated a:hover{
	color:var(--color-gold);
	background: radial-gradient(50% 100% at 50% 100%, #FFF9E2 0%, rgba(255, 255, 255, 0) 100%);
}
#header .sideBar ul li a:hover{
	background: radial-gradient(50% 100% at 50% 100%, #FFF9E2 0%, rgba(255, 255, 255, 0) 100%);
	text-decoration: none;
}
#header .sideBar .btn{
	border-radius: 0;
	padding:2px !important;
	margin:0 20px 20px 20px;
}
#header .sideBar .btn div{
	margin:0px;
	font-size: 1.4rem;
	line-height: 1.8rem;
	padding:1rem !important;
}

#header .side-bar-overlay{
	position:fixed;
	z-index:1;
	top:0;
	left:0;
	right:0;
	bottom:0;
	display:none;
}
#header .sideBar .badge{
	position:absolute;
	top:0;
	right:0;
	border-radius:0 0 5px 5px;
	font-size:1.2rem;
	font-weight: 700;
}

#header .sideBar .online-status{
	position:absolute;
	top:50%;
	transform: translateY(-50%);
	right:1rem;
}
#header .sideBar .online-status.top{
	top:2.4rem;
	transform: translateY(0);
}

#header .sideBar .dot,
#room #room-header .dot{
	position:absolute;
	content:" ";
	width: 15px;
	height: 15px;
	background: linear-gradient(264.66deg, #ffddc6 0%, #ffeadb 32.87%, #ffeadb 50.8%, #fac39b 101.49%);
	border-radius: 50%;
}
#room #room-header  .dot.tl{
	left: 12px;
    top: 16px;
}
#room #room-header  .dot.tr{
	right: 12px;
    top: 16px;
}
#header .sideBar .dot.tl{
	left:22px;
	top:22px;
}
#header .sideBar .dot.tr{
	right:22px;
	top:22px;
}
#header .sideBar .dot.br{
	right:22px;
	bottom:22px;
}
#header .sideBar .dot.bl{
	left:22px;
	bottom:22px;
}

#header .sideBar ul li a.new-video:after{
	content:"";
	position:absolute;
	top:50%;
	right:10px;
	transform:translateY(-50%);
	background-image:url('../Images/star.svg');
	width:14px;
	height:14px;
}
#header .sideBar ul li ul li a .play,
#header .sideBar ul.all li a .play{
	display:none;
}

#header .sideBar ul li ul li a.active .play,
#header .sideBar ul li ul li a .star{
	position: absolute;
    top: 50%;
    left: -32px;
    transform: translateY(-50%);
    display: block;
    height: 20px;
    width: 2rem;
}

#header .sideBar ul.all li a.active  .play,
#header .sideBar ul.all li a .star{
	position: absolute;
    top: 50%;
    left: -7px;
    transform: translateY(-50%);
    display: block;
    height: 20px;
    width: 2rem;
}

#layout{
	overflow:hidden;
}
#notification{
    position:fixed;
    width:300px;
    bottom:20px;
	right:20px;	
	z-index:99999;
}
#notification .notify{
    margin-top:15px;
    padding:10px;
    background-color:#ffffff;
    border:1px solid rgba(0,0,0,.125);	
}
#notification .notify .alert{
    margin-bottom:0px;	
}
#video{
	padding: 45px 0px 0px 250px;
	height:100vh;
	background-color: #111;
}

.cover-item{
	border: 1px solid #ddd;
    padding: 15px;
}

.cover-item .menu-item,
.main-photo-container .menu-item{
	padding: 10px;
    position: absolute;
    top: 0px;
    right: 15px;
    border: 1px solid #ddd;
	line-height: 10px;
	background-color:#ffffff;
}

.cover-item .edit-image,
.cover-item .remove-image,
.main-photo-container .edit-image{
	top: 34px;
	padding: 7px 5px;
	width: 35px;
	cursor:pointer;
}
.main-photo-container .edit-image{
	top: 0px;
}
.file-list-item{
	overflow:hidden
}
.file-list-item img{
	max-width:100%;
}
.file-list-item img.icon{
	height:50px;
}
.cover-select,
.image-select{
	position:relative;
}
.cover-select .value,
.image-select .value{
	background-color: #eeeeee;
    border: 0px none;
    color: #7d7d7d;
    box-shadow: none;
	font-size: 0.75rem;
	align-items: center;
	height: 46px;
	line-height:1.5;
	font-weight:400;
	width: 100%;
	cursor:pointer;
	display:flex;
	border-radius: .25rem;
	border:1px solid #eee;
}
.image-select .value{
	height:auto;
}
.cover-select .items,
.image-select .items{
	position:absolute;
	top:43px;
	left:0px;
	width:100%;
	z-index:100;
	height:200px;
	overflow-y: auto;
	background-color: #fff;
}
.image-select .items{
	top:-200px;
}

.cover-select .item,
.image-select .item{
	display:flex;
	height: 46px;
	border-bottom:1px solid #ddd;
	border-left:1px solid #ddd;
	border-right:1px solid #ddd;
	font-size:0.75rem;
	align-items: center;
	cursor:pointer;
	background-color:#fff;
	padding:0px !important;
}
.image-select .item{
	height: auto;
}
.cover-select img{
	height: 46px;
	margin-right:10px;
}
.image-select img{
	max-width: 100%;
	max-height: 92px;
}
.image-select .items img{
	height: auto;
	max-height:auto;
	width:100%;
}
.cover-select .item:hover,
.image-select .item:hover{
	background-color:#00aeef;
	color:#fff;
}

.data-list{
	list-style-type: none;
	padding:0px;
	margin:0px;
}
.data-list-item-content{
	display:flex;
	border-bottom:1px solid #ddd;
	justify-content: center;
    align-items: center;
}
.data-list-item.header{
	border-bottom:4px solid #ddd;
}
.data-list-item.header div{
	font-weight:bold;
	font-size:0.85rem;
}
.data-list-item-content div,
.data-list-item-content .item{
	padding:10px;
	flex:1;
	font-size:0.85rem;
	margin:0px;
}
.data-list-item-content div div{
	padding:0px;
}
.data-list-item-content.categories div{
	flex:none;
	width:20%;
}
.data-list-item-content img.product{
	height:50px;
}

.data-list-item-content .double-comb-line{
	height:47px;
	align-items: center;
	display: flex;
}
.data-list-item-content .double-comb-line img{
	max-height:100%;
}
.data-list-item-content .double-comb-col{
	height:114px;
}
.data-list-item-content .cover-image{
	width:94px;
	height:94px;
	position:relative;
}
.data-list-item-content .cover-image .first{
	clip-path: polygon(0% 0%, 100% 0%, 0% 100%);
	width:94px;
	height:94px;
	position:absolute;
	top:0px;
	left:0px;
	background-position: center center;
	background-size: cover;
}
.data-list-item-content .cover-image .second{
	clip-path: polygon(100% 0%, 100% 100%, 0% 100%);
	width:94px;
	height:94px;
	position:absolute;
	top:0px;
	left:0px;
	background-position: center center;
	background-size: cover;
}
.data-list-item-content.line-top{
	border-top:4px solid #ddd;
}
.products-list{
	border-top:2px solid #ddd;
	border-bottom:2px solid #ddd;
}

.main-photo-container{
	border:1px solid rgba(0,0,0,.125);
	padding:15px;
}
.main-photo-container img{
	max-width:100%;
}
.main-photo-container .one-image{
	position:relative;
}
.main-photo-container .one-image .menu-item{
	right:0px;
}

textarea.form-control,
input.form-control,
select.form-control,
select.form-control:focus,
select.form-control:active,
input.form-control:focus,
input.form-control:active,
.input-group-text,
.form-control:disabled, 
.form-control[readonly]{
	background-color:var(--color-input);
	border:0px none;
	color:var(--color-dark);
	box-shadow:none;
	font-size:1.4rem;
	padding:1.5rem 1.8rem;
	height:46px;
	border-radius: 10px;
}
textarea.form-control{
	height:100px;
}
.edit-icon,
.remove-icon{
	height:16px;
	cursor:pointer;
}
.move-icon{
	height:18px;
}
label{
	font-size: 1.4rem;
}
label.input-label{
	font-weight:bold;
}
.input-group-text{
	font-size:1rem;
}
.card{
	background: #FFFFFF !important;;
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15) !important;;
	border-radius: 10px !important;;
	border:0px none !important;
	margin-bottom:4rem;
}
.card .card-header,
.modal-header{
	font-weight: 700;
	font-size: 18px;
	line-height: 18px;
	text-align: center;
	text-transform: uppercase;
	color: var(--color-dark);
	padding: 0px 55px;
	border-bottom:1px solid var(--color-very-light-gray);
	background: linear-gradient(269.27deg, var(--color-light-gold) 0%, #FFFFFF 50.56%);
	border-radius: 10px 10px 0px 0px;
	position:relative;
	height:56px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.card .card-header:before,
.card .card-header:after,
.modal-header:before,
.modal-header:after{
	position:absolute;
	content:" ";
	width: 15px;
	height: 15px;
	top: 21px;
	background: linear-gradient(264.66deg, #ffddc6 0%, #ffeadb 32.87%, #ffeadb 50.8%, #fac39b 101.49%);
	border-radius: 50%;
}
.card .card-header:before,
.modal-header:before{
	left:22px;
}
.card .card-header:after,
.modal-header:after{
	right:22px;
}
.card .card-header select,
.card .card-header .form-control:focus,
.card .card-header .form-control:active{
	width:110px;
	border:1px solid #F4E9CF;
	background-color: #fff;;
}
.card .card-header.full-radius{
	border-radius:1rem;
}
.ReactModal__Content .close{
	opacity:1 !important;
	cursor:pointer;
	margin: 2px 0 0 auto !important;
	padding:0 !important;
	height:24px;
}

.card .card-body{
	padding:22px;
}
.card .card-body .filter-data{
	border-bottom:2px solid #fff;
	position:relative;
	margin-bottom:2rem;
	display:flex;
	font-size: 1.4rem;
	flex-wrap: wrap;
}
.card .card-body .filter-data .item{
	margin-right:10px;
}
.card .card-body .filter-data:after{
	content: " ";
    position: absolute;
    height: 4px;
    width: 100%;
    background-color: #fff;
    bottom: -4px;
    left: 0;
    box-shadow: 0px 0px 15px rgb(0 0 0 / 15%) !important;
}
.no-video{
	height:100vh;
	width:100%;
	display:flex;
	align-items: center;
	justify-content: center;
	height: calc(100vh - 65px);
}
.no-video div{
	color:#ccc;
	font-size:1.5rem;
}
.no-video div.alert{
	color:#721c24;
}

.table th{
	font-size:0.75rem;
	font-weight:700;
	padding: .5rem;
}
.table td{
	font-size:0.75rem;
	padding: .5rem;
}
.alert{
	font-size:1.4rem;
	border-radius:1rem;
	font-weight: 600;
	margin:1rem 0 0 0;
}
.alert-danger{
	background: linear-gradient(180deg, #EA7782 0%, var(--color-red) 100%) !important;
	color:#fff;
}
.alert-success{
	background: linear-gradient(180deg, var(--color-green-light) 0%, var(--color-green) 100%) !important;
	color:#fff;
}
.alert-warning{
	background: linear-gradient(180deg, var(--color-light-gold) 0%, var(--color-light-gold) 100%) !important;
	color:var(--color-dark-gold);
}
.btn{
	font-size:1.4rem !important;
	line-height: 1.4rem !important;
	text-transform: uppercase !important;
	padding:1.6rem !important;
	font-weight:700 !important;
	border-radius: 1rem !important;
	border:0px none;
}
.btn:active{
	color:var(--color-dark) !important;
}
.btn.focus, .btn:focus{
	box-shadow: none !important;
	color:var(--color-dark) !important;
}
.btn-thiner{
	padding:1rem 1.2rem !important;
}
.btn-thinest{
	padding:0.5rem 1.2rem !important;
	font-size:1.2rem !important;
}
.btn-primary,
.btn-primary:hover {
    background: linear-gradient(180deg, var(--color-semi-gold) 14.06%, var(--color-gold) 100%);
	color:#8A4C26;
}
.btn-secondary,
.btn-secondary:hover {
    background: linear-gradient(180deg, #c2c2c2 14.06%, #878787  100%);
	color:#8A4C26;
}

.btn-danger,
.btn-danger:hover{
	background: linear-gradient(180deg, #EA7782 0%, var(--color-red) 100%) !important;
	color:#fff;
}
.btn-danger:active,
.btn-danger:focus{
	color:#fff !important;
}
.btn-delete-files{
	line-height: 10px;
    padding: 10px;
	height: 35px;
	border: 1px solid #ced4da;
}
.btn-delete-files:hover{
	border: 1px solid #ced4da;
}
.nav-tabs{
	margin-bottom:20px;
}
.nav-link {
    padding: 1rem 1.5rem;
}
.nav-tabs .nav-link.disabled{
	color:#cccccc;
	cursor:not-allowed;
	pointer-events:initial;
}
.nav-tabs .nav-link.active{
	background-color:#00aeef;
	color:#fff;
}
.modal-content {
    border-radius: 1rem;
}
.modal-body p{
	font-size:1.4rem;
}
.delete-checked{
	vertical-align: middle;
	margin-left: 4px;
}
.info-desc{
	font-size:12px;
	font-style: italic;
}
.image-mirror{
	-webkit-transform: scaleX(-1);
  	transform: scaleX(-1);
}
.StripeElement{
	padding: 20px;
    background: #eee;
    border-radius: 5px 5px 0 0;
}
.whole-loading{
	display:flex;
	height:100%;
	align-items:center;
	justify-content:center;
	padding: 50px 0px;
}
.loading{
	text-align:center;
	font-size:0.875rem;
}
.no-flex{
	flex:none !important;
}
.no-margin{
	margin:0px !important;
}
.w-40{
	width:40px;
}
.fs-14{
	font-size:1.4rem;
}
.pl-15{
	padding-left:15px !important;
}
.p-15{
	padding:15px !important;
}
.p-12{
	padding:12px !important;
}
.bold{
	font-weight:bold;
}
.hide{
	display:none;
}
.badge.not-specified{
	color:#fff;
}
.maxwidth-100{
	max-width:100%;
}

.modal-lg, .modal-xl {
    max-width: 1199px !important;
}
.custom-file-input{
	cursor:pointer;
}
.custom-file-label::after {
	content: "Vybrat" !important;
	background-color:#00aeef;
	color:#fff;
}
.badge-danger{
	background: linear-gradient(180deg, #EA7782 0%, var(--color-red) 100%) !important;
}
.badge-success{
	background: linear-gradient(180deg, var(--color-green-light) 0%, var(--color-green) 100%) !important;
}
.badge-warning{
	background: linear-gradient(180deg, #fff3cd 0%, #ffeeba 100%) !important;
}
.badge{
	position:relative;
	white-space: initial !important;
}
.badge img{
	height:15px !important;
	margin-left:2px;
}
.badge-info-text-overlay{
	position: fixed;
	top:0;
	left:0;
	right:0;
	bottom:0;
	z-index: 1000;
}
.badge-info-text{
	position:absolute;
	top:25px;
	right:0px;
	background-color: rgba(0, 0, 0, 0.85);
	color:#fff;
	padding:1rem;
	border-radius: 1rem;
	width:200px;
	z-index: 10;
	line-height: 1.4rem;
    font-weight: 300;
}

.cursor-pointer{
	cursor:pointer;
}

.ReactModal__Overlay {
  opacity: 0;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.8) !important;
  z-index:1300 !important;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  transition: opacity 150ms ease-out;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Content.modal-dialog {
  border: none;
  background-color: transparent;
}

.ReactCrop__image{
	max-height:100% !important;
}

.spinner {
  margin: 10px auto;
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;
}

.spinner > div {
  background-color: #00aeef;
  height: 100%;
  width: 6px;
  display: inline-block;
  margin-right:2px;
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinner .rect3 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% { -webkit-transform: scaleY(0.4);}  
  20% { -webkit-transform: scaleY(1.0) }
}

@keyframes sk-stretchdelay {
  0%, 40%, 100% { 
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }  20% { 
    transform: scaleY(1.0);
    -webkit-transform: scaleY(1.0);
  }
}

@media (min-width: 576px){
	.modal-dialog {
		max-width: 500px;
		margin: 0rem auto;
		padding: 1.75rem 0rem;
	}
}

