@media (max-width: 991.98px) {
	
	#header .menu-icon{
        display:inline-block;
    }
    #header ul li.membership,
    #header ul li.renew-membership {
        display:none;
    }
    #header ul li.mobile-membership{
        display:inline-block;
    }
    .whole-container{
        padding: 65px 15px 0px 15px;
    }
    #video {
        padding: 45px 0px 0px 0px;
        height: 100vh;

    }
    #header .sideBar {
        transform: translateX(-300px);
        top:46px;
        padding-top:5.5rem;
    }
    #header .sideBar .logo{
        display:none;
    }
}