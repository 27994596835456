@media (max-width: 1499.98px) {

	.card {
        margin-bottom: 3rem;
    }
    .card .card-header, .modal-header {
        font-size: 16px;
        line-height: 17px;
        padding: 0px 48px;
        height: 46px;
    }
    .card .card-header:before, 
    .card .card-header:after, 
    .modal-header:before, 
    .modal-header:after {
        top: 17px;
    }
    .card .card-header:before, 
    .modal-header:before {
        left: 17px;
    }
    .card .card-header:after, 
    .modal-header:after {
        right: 17px;
    }
    .card .card-body {
        padding: 18px;
    }

    .ReactModal__Content .close {
        margin: 3px 0 0 auto !important;
    }
}