@media (max-width: 575.98px) {

    body{
        background-size: 1200px 800px;
    }
    
    #login .card {
        width: 90%;
    }
    #login .logo img{
       height:110px;
       margin-top:50px;
       margin-bottom:50px;
    }
    
    #header ul li.logo{
        width:50px;
    }

    #header ul li.logo img {
        margin-right: 0px;
    }
    #header .sideBar {
        width: 100%;
        transform: translateX(-100%);
    }
    #header ul li.mobile-membership ul li {
        display: block;
        border:0px none;
    }
    #header ul li.mobile-membership ul li button{
        margin:0 auto;
    }

    .card .card-header:before, 
    .card .card-header:after, 
    .modal-header:before, 
    .modal-header:after {
        width: 10px;
        height: 10px;
        top:13px;
    }
    .card .card-header:before, 
    .modal-header:before {
        left: 13px;
    }
    .card .card-header:after, 
    .modal-header:after {
        right: 13px;
    }
    .card .card-header, .modal-header {
        padding: 0px 33px;
        font-size: 14px;
        height: 36px;
    }
    .card .card-header select{
        flex: 0 0 50%;
    }
    .card .select-container{
        width:100%;
        margin-top: 5px;
    }
    .card.profits .card-header{
        height:7rem;
    }
    .card .card-header.flex-column{
        height:7rem;
    }
    .card .card-header select {
        flex: 0 0 50%;
    }

    .ReactModal__Content .close {
        margin: 0px 0 0 auto !important;
        height:20px;
    }

    .modal-lg {
        margin:0.5rem;
    }

    .reg-title{
        font-size:0.875rem;
    }

    .btn-thiner {
        padding: 0.5rem 1.2rem !important;
    }
    .modal-dialog {
        margin: 1rem auto;
    }

}
	